import React from "react"
import styled from "styled-components"

import logoSpearly from "../../assets/logo-spearly.svg"

import LanguageDropdown from "./LanguageDropdown"
export const LoginHeader = () => {
  return (
    <StyledHeader>
      <StyledLogo src={logoSpearly} alt="Spearly" />
      <DropdownContainer>
        <LanguageDropdown />
      </DropdownContainer>
    </StyledHeader>
  )
}

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
`

const StyledLogo = styled.img`
  width: 166px;
`

const DropdownContainer = styled.div`
  padding-right: 40px;

  @media screen and (max-width: 1000px) {
    padding-right: 0;
  }
`

export default LoginHeader
