import Cookies from "js-cookie"

import { OauthAction } from "../interfaces"

const actionCookieName = "SpearlyOauthAction"

// MEMO: GitHubログインだとURLクエリパラメータでの引き渡しができないので、 Cookie 経由で引き渡す
export const setSpearlyOauthAction = (oauthAction: OauthAction) => {
  Cookies.set(actionCookieName, oauthAction, {
    secure: true,
    domain: process.env.REACT_APP_SPEARLY_COOKIE_DOMAIN,
  })
}

export const getSpearlyOauthAction = () => {
  const value = Cookies.get(actionCookieName)
  if (!value) return null

  return value as OauthAction
}

export const resetSpearlyOauthAction = () => {
  Cookies.remove(actionCookieName, {
    secure: true,
    domain: process.env.REACT_APP_SPEARLY_COOKIE_DOMAIN,
  })
}
