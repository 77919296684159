import React from "react"
import styled from "styled-components"

import logoSpearly from "../../assets/logo-spearly.svg"
import { t } from "../../lib/i18n"

const SignupMain = (): JSX.Element => {
  return (
    <Container>
      <StyledH1>
        <StyledLogo src={logoSpearly} alt="Spearly" />
      </StyledH1>
      <StyledH2>
        {t("create_account1")}
        <br />
        {t("create_account2")}
      </StyledH2>
      <Description>
        {t("create_account_description1")}
        <br />
        {t("create_account_description2")}
      </Description>
    </Container>
  )
}

const Container = styled.div`
  font-family: "Public Sans";
  display: flex;
  flex-direction: column;
`

const StyledH1 = styled.h1`
  text-align: center;
  padding-bottom: 32px;
`

const StyledLogo = styled.img`
  width: 180px;
`

const StyledH2 = styled.h2`
  color: #171721;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  margin: 0 auto 23px;
`

const Description = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #000;
`

export default SignupMain
