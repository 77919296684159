import { useGoogleLogin } from "@react-oauth/google"
import { notification } from "antd"
import React from "react"

import { useOauth } from "../../hooks/useOauth"
import { NetworkUnauthorizedError, OauthAction } from "../../interfaces"
import { t } from "../../lib/i18n"
import LoginOauthButton from "../Login/LoginOauthButton"
import SignupOauthButton from "../Signup/SignupOauthButton"

type GoogleOauthButtonProps = {
  oauthAction: OauthAction
  isVisible: boolean
}

const GoogleOauthButton = (props: GoogleOauthButtonProps): JSX.Element => {
  const { onGoogle } = useOauth(props.oauthAction)

  if (!props.isVisible) {
    return <></>
  }

  const onClick = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        await onGoogle(codeResponse.access_token)
      } catch (error) {
        if (error instanceof NetworkUnauthorizedError) {
          if (error.message === "401 Unauthorized") {
            notification.error({
              message: t("account_not_found"),
            })
          } else {
            notification.error({
              message: t("account_already_registered"),
            })
          }
          return
        }
        notification.error({
          message: t("unknown_error"),
        })
      }
    },
    onError: () => {
      notification.error({
        message: t("unknown_error"),
      })
    },
    onNonOAuthError: () => {
      notification.error({
        message: t("unknown_error"),
      })
    },
  })

  if (props.oauthAction === "signup") {
    return (
      <SignupOauthButton
        isVisible={props.isVisible}
        oauthType="google"
        onClick={onClick}
        className="cta-signup"
      />
    )
  }

  return (
    <LoginOauthButton
      isVisible={props.isVisible}
      oauthType="google"
      onClick={onClick}
    />
  )
}

export default GoogleOauthButton
