import React, { useEffect } from "react"
import { Switch, Route, useLocation, useHistory } from "react-router-dom"

import GitHubCallback from "pages/GitHubCallback"
import Login from "pages/Login"
import LoginCallback from "pages/LoginCallback"
import Payment from "pages/Payment"
import PaymentSetupComplete from "pages/PaymentSetupComplete"
import Plan from "pages/Plan"
import Profile from "pages/Profile"
import SignUp from "pages/SignUp"
import Team from "pages/Team"
import TeamSeatCheckout from "pages/TeamSeatCheckout"

import NotFound from "../components/Error/NotFound"

import routes from "./routes"

function Router(): JSX.Element {
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (location.pathname === routes.Top.path) {
      history.push(routes.Profile.path)
    }
  }, [location])

  return (
    <Switch>
      <Route path={routes.GitHubCallback.path}>
        <GitHubCallback />
      </Route>

      <Route path={routes.LoginCallback.path}>
        <LoginCallback />
      </Route>

      <Route path={routes.Login.path}>
        <Login />
      </Route>

      <Route path={routes.SignUp.path}>
        <SignUp />
      </Route>

      <Route path={routes.PaymentSetupComplete.path}>
        <PaymentSetupComplete />
      </Route>

      <Route path={routes.Payment.path}>
        <Payment />
      </Route>

      <Route path={routes.Profile.path}>
        <Profile />
      </Route>

      <Route path={routes.TeamSeatCheckout.path}>
        <TeamSeatCheckout />
      </Route>

      <Route path={routes.CmsTeamSeatCheckout.path}>
        <TeamSeatCheckout serviceName="cms" />
      </Route>

      <Route path={routes.CloudTeamSeatCheckout.path}>
        <TeamSeatCheckout serviceName="cloud" />
      </Route>

      <Route exact path={routes.TeamCreate.path}>
        <Profile />
      </Route>

      <Route exact path={routes.CmsPlan.path}>
        <Plan serviceName="cms" />
      </Route>

      <Route exact path={routes.CloudPlan.path}>
        <Plan serviceName="cloud" />
      </Route>

      <Route path={routes.Team.path}>
        <Team />
      </Route>

      <Route exact path={routes.CmsTeam.path}>
        <Team serviceName="cms" />
      </Route>

      <Route exact path={routes.CloudTeam.path}>
        <Team serviceName="cloud" />
      </Route>

      <Route exact path={routes.Top.path}>
        <Profile />
      </Route>

      <Route component={NotFound} />
    </Switch>
  )
}

export default Router
