import { GoogleOAuthProvider } from "@react-oauth/google"
import { notification } from "antd"
import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { trackEvent } from "lib/eventTracker"

import SignupAnim from "../components/Signup/SignupAnim"
import SignupMain from "../components/Signup/SignupMain"
import SignupOauthButton from "../components/Signup/SignupOauthButton"
import SpSignupAttention from "../components/Signup/SpSignupAttention"
import TermsConfirm from "../components/Signup/TermsConfirm"
import GoogleOauthButton from "../components/molecules/GoogleOauthButton"
import { useOauth } from "../hooks/useOauth"
import { t } from "../lib/i18n"

const SignUp = (): JSX.Element => {
  const { onGitHub, generateLoginUrl } = useOauth("signup")
  const searchParams = new URLSearchParams(window.location.search)
  const errorMessage = searchParams.get("error")
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ""

  useEffect(() => {
    trackEvent({ eventName: "Visit Sign Up Page" })
  }, [])

  useEffect(() => {
    if (errorMessage === "422") {
      notification.error({
        message: t("account_already_registered"),
      })
    }
  }, [errorMessage])

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <Root>
        <AnimContainer>
          <SignupAnim />
        </AnimContainer>
        <SignupMenu>
          <SignupMain />
          <ButtonList>
            <GoogleOauthButton isVisible={true} oauthAction="signup" />
            <SignupOauthButton
              isVisible={true}
              oauthType="github"
              className="cta-signup"
              onClick={() => onGitHub()}
            />
          </ButtonList>
          <TermsConfirm />
          <SignupAttentionWrapper>
            <SpSignupAttention />
          </SignupAttentionWrapper>
          <Link to={generateLoginUrl()}>
            <Login>{t("click_have_account_already")}</Login>
          </Link>
        </SignupMenu>
      </Root>
    </GoogleOAuthProvider>
  )
}

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  height: 100vh;
  padding: 30px;
  gap: 50px 0;
`

const AnimContainer = styled.div`
  width: 50%;

  @media screen and (max-width: 1000px) {
    display: none;
  }
`

const SignupMenu = styled.div`
  position: relative;
  width: 50%;
  max-width: 520px;
  height: calc(100vh - 60px);
  max-height: 842px;
  padding: 30px;

  @media screen and (max-width: 1000px) {
    width: 100%;
    padding: 0;
  }
`

const ButtonList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin: 51px auto;
`

const SignupAttentionWrapper = styled.div`
  margin-top: 30px;
`

const Login = styled.span`
  position: absolute;
  bottom: 30px;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  color: #000;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  text-decoration-line: underline;

  @media screen and (max-height: 670px) {
    padding-top: 30px;
    position: relative;
  }
`

export default SignUp
