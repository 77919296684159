import React, { useMemo } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { t } from "../../lib/i18n"

const LoginAttention = (): JSX.Element => {
  const searchParams = new URLSearchParams(window.location.search)
  const authedRedirectUri = searchParams.get("redirect_uri")
  const nonce = searchParams.get("nonce")

  const signupUrl = useMemo(() => {
    if (nonce) {
      return `/signup?nonce=${nonce}`
    }

    if (!authedRedirectUri) {
      return "/signup"
    }

    return `/signup?redirect_uri=${authedRedirectUri}`
  }, [authedRedirectUri, nonce])

  return (
    <PromoteLink>
      {t("dont_have_account")}
      <StyledLink to={signupUrl}>{t("sign_up_account")}</StyledLink>
    </PromoteLink>
  )
}

const PromoteLink = styled.p`
  color: #b0b7c3;
  text-align: center;
`

const StyledLink = styled(Link)`
  display: inline-block;
  padding-left: 5px;
`

export default LoginAttention
