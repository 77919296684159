import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

export const useLoginButtonVisibility = () => {
  const location = useLocation()
  const [showGoogleButton, setShowGoogleButton] = useState<boolean>(false)
  const [showGithubButton, setShowGithubButton] = useState<boolean>(false)

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const provider = queryParams.get("provider")

    if (!provider) {
      setShowGoogleButton(true)
      setShowGithubButton(true)
      return
    }

    if (provider.includes("google")) setShowGoogleButton(true)
    if (provider.includes("github")) setShowGithubButton(true)
  }, [location])

  return {
    showGoogleButton,
    showGithubButton,
  }
}
