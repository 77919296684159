import { UserRole } from "../consts"
import { Route, Routes } from "../interfaces"
import { ServiceNameMap } from "../lib/service"

const routes: Routes = {
  GitHubCallback: {
    path: "/github_callback",
    visibleAll: true,
  },
  Login: { path: "/login", visibleAll: true },
  SignUp: { path: "/signup", visibleAll: true },
  LoginCallback: { path: "/login/callback", visibleAll: true },
  PaymentSetupComplete: {
    path: "/payment/setup-complete",
    visibleRoles: [UserRole.OWNER, UserRole.ADMIN],
  },
  Payment: {
    path: "/payment",
    visibleRoles: [UserRole.OWNER, UserRole.ADMIN],
  },
  PlanDetail: {
    path: "/plan",
    visibleRoles: [UserRole.OWNER, UserRole.ADMIN],
  },
  Profile: {
    path: "/profile",
    visibleRoles: [
      UserRole.OWNER,
      UserRole.ADMIN,
      UserRole.DEVELOPER,
      UserRole.EDITOR,
      UserRole.WRITER,
    ],
  },
  TeamSeatCheckout: {
    path: "/team/seat/checkout",
    visibleRoles: [UserRole.OWNER, UserRole.ADMIN],
  },
  TeamCreate: {
    path: "/team/create",
    visibleRoles: [
      UserRole.OWNER,
      UserRole.ADMIN,
      UserRole.DEVELOPER,
      UserRole.EDITOR,
      UserRole.WRITER,
    ],
  },
  CmsTeamSeatCheckout: {
    path: "/cms/team/seat/checkout",
    visibleRoles: [UserRole.OWNER, UserRole.ADMIN],
  },
  CloudTeamSeatCheckout: {
    path: "/cloud/team/seat/checkout",
    visibleRoles: [UserRole.OWNER, UserRole.ADMIN],
  },
  Team: {
    path: "/team",
    visibleRoles: [UserRole.OWNER, UserRole.ADMIN],
  },
  CmsTeam: {
    path: "/cms/team",
    visibleRoles: [UserRole.OWNER, UserRole.ADMIN],
    service: ServiceNameMap.CMS,
  },
  CloudTeam: {
    path: "/cloud/team",
    visibleRoles: [UserRole.OWNER, UserRole.ADMIN],
    service: ServiceNameMap.CLOUD,
  },
  CmsPlan: {
    path: "/cms/plan",
    visibleRoles: [UserRole.OWNER, UserRole.ADMIN],
    service: ServiceNameMap.CMS,
  },
  CloudPlan: {
    path: "/cloud/plan",
    visibleRoles: [UserRole.OWNER, UserRole.ADMIN],
    service: ServiceNameMap.CLOUD,
  },
  Top: { path: "/", visibleAll: true },
}

export const getRouteByPath = (pathname: string): Route => {
  const routeList = Object.entries(routes)
  const route = routeList.find((route) => pathname.startsWith(route[1].path))
  if (!route) {
    throw new Error("routeが存在しません")
  }
  return routes[route[0]]
}

export default routes
