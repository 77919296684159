import { notification } from "antd"
import React, { useEffect } from "react"
import { ErrorBoundary, FallbackProps } from "react-error-boundary"
import { useHistory } from "react-router-dom"

import { t } from "lib/i18n"
import logger from "lib/logger"

type ApplicationErrorBoundaryProps = {
  children: React.ReactNode
}
const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const history = useHistory()

  useEffect(() => {
    logger("Something wrong!", error)
    notification.error({
      message: t("unknown_error"),
    })
    history.push("/login")
    resetErrorBoundary()
  }, [error])

  return null
}

const ApplicationErrorBoundary = (props: ApplicationErrorBoundaryProps) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {props.children}
    </ErrorBoundary>
  )
}

export { ApplicationErrorBoundary }
