import React from "react"
import styled from "styled-components"

import iconGithub from "assets/icon-github.svg"
import iconGoogle from "assets/icon-google.svg"

import { OauthButtonProps, OauthType } from "../../interfaces"
import { t } from "../../lib/i18n"

const iconImageHash: { [key in OauthType]: string } = {
  google: iconGoogle,
  github: iconGithub,
}

const SignupOauthButton = (props: OauthButtonProps): JSX.Element => {
  return (
    <StyledButton onClick={props.onClick} className={props.className}>
      <StyledIcon src={iconImageHash[props.oauthType]} alt="" />
      {t("signup_with_oauth", t(props.oauthType))}
    </StyledButton>
  )
}

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 13px;
  background: #fff;
  color: #8083a3;
  font-family: "Public Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  border: 1px solid #e4e6e8;
  border-radius: 10px;
  width: 420px;
  height: 54px;

  @media screen and (max-width: 1000px) {
    min-width: 250px;
    width: 100%;
  }
`

const StyledIcon = styled.img`
  width: 21px;
  height: 21px;
`

export default SignupOauthButton
