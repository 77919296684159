import React from "react"
import styled from "styled-components"

import iconGithub from "assets/icon-github.svg"
import iconGoogle from "assets/icon-google.svg"

import { OauthButtonProps, OauthType } from "../../interfaces"
import { t } from "../../lib/i18n"

const iconImageHash: { [key in OauthType]: string } = {
  google: iconGoogle,
  github: iconGithub,
}

const LoginOauthButton = (props: OauthButtonProps): JSX.Element => {
  if (!props.isVisible) {
    return <></>
  }

  return (
    <StyledButton onClick={props.onClick}>
      <StyledIcon src={iconImageHash[props.oauthType]} alt="" />
      <StyledLabel>{t("signin_with_oauth", t(props.oauthType))}</StyledLabel>
    </StyledButton>
  )
}

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: 1px solid #e4e6e8;
  border-radius: 15px;
  width: 255px;
  height: 58px;
  gap: 0 13px;
`

const StyledIcon = styled.img`
  width: 21px;
  height: 21px;
`

const StyledLabel = styled.span`
  color: #8083a3;
  font-family: "Public Sans";
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
`

export default LoginOauthButton
