import React from "react"
import styled from "styled-components"

import { t } from "../../lib/i18n"

const LoginMain = (): JSX.Element => {
  return (
    <>
      <StyledH1>{t("sign_in")}</StyledH1>
      <Description>{t("welcome_home")}</Description>
    </>
  )
}

const StyledH1 = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 29px;
  text-align: center;
  color: #171721;
  margin-bottom: 25px;
`

const Description = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  text-align: center;
  color: #8a94a6;
  margin: 0;
`

export default LoginMain
