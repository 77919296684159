import parse from "html-react-parser"
import React from "react"
import styled from "styled-components"

import { t } from "../../lib/i18n"

// MEMO: 「%s、%sに同意する」 の %s をリンク付きテキストに変える
const TermsConfirm = (): JSX.Element => {
  const sentence = t("terms_confirm_text")

  const privacyLink = () => {
    return `<a href="https://cms.spearly.app/privacy" target="_blank" rel="noreferrer" class="spearly-terms-confirm">
        ${t("cookie_preferences_privacy")}
      </a>`
  }

  const termsLink = (): string => {
    return `<a href="https://cms.spearly.app/term" target="_blank" rel="noreferrer" class="spearly-terms-confirm">
        ${t("terms")}
      </a>`
  }

  return (
    <ConfirmText>
      {parse(sentence.replace("%s", termsLink()).replace("%s", privacyLink()))}
    </ConfirmText>
  )
}

const ConfirmText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  text-align: center;
  color: #8083a3;
  max-width: 420px;

  a {
    text-decoration: underline;
  }

  @media screen and (max-width: 1000px) {
    margin: 0 auto;
  }
`
export default TermsConfirm
