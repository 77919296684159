import { atom } from "recoil"

import { ProfileState } from "../../interfaces/profile"

export const profileState: ProfileState = {
  id: "",
  name: "",
  avatarUrl: "",
  locale: "ja",
}

const useProfile = atom({
  key: "profile",
  default: profileState,
})

export default useProfile
