import React, { useMemo } from "react"
import styled from "styled-components"

import logoSpearlyWhite from "assets/logo-spearly-white.svg"
import logoSpearlyBlack from "assets/logo-spearly.svg"
import { t } from "lib/i18n"

import { isLightTheme } from "../../lib/theme"

type LoadingProps = {
  isFigmaPlugin?: boolean
}

const Loading = (props: LoadingProps) => {
  const LoadingText = useMemo(() => {
    if (props.isFigmaPlugin) {
      return (
        <Small2>
          {t("loading_figma1")}
          <br />
          {t("loading_figma2")}
        </Small2>
      )
    }
    return <Small2>{t("loading")}</Small2>
  }, [props.isFigmaPlugin])

  return (
    <Root>
      <LogoGroup>
        <Logo
          src={isLightTheme() ? logoSpearlyBlack : logoSpearlyWhite}
          alt="Spearly"
        />
      </LogoGroup>
      {LoadingText}
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
  height: 100vh; // fix me!
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;
`

const LogoGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
`

const Logo = styled.img`
  width: 300px;
`

const Small2 = styled.p`
  color: #8a94a6;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`

export { Loading }
