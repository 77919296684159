import api from "lib/api"

import { OauthAction } from "../interfaces"
import { removeSpearlyToken } from "../lib/spearlyToken"

import { removeCurrentTeam } from "./currentTeam"

type PostGoogleCallbackPayload = {
  accessToken: string
  clientId: string
  redirectUri: string
  oauthAction?: OauthAction
}

export const postGoogleCallback = async (
  payload: PostGoogleCallbackPayload,
) => {
  return await api<any>(
    "post",
    "oauth/google/callback",
    {
      access_token: payload.accessToken,
      client_id: payload.clientId,
      redirect_uri: payload.redirectUri,
      oauth_action: payload.oauthAction,
    },
    {
      login: true,
    },
  )
}

type PostGithubCallbackPayload = {
  clientId: string
  code: string
  redirectUri: string
  oauthAction?: OauthAction
}

export const postGithubCallback = async (
  payload: PostGithubCallbackPayload,
) => {
  return await api<any>("post", "oauth/github/callback", {
    client_id: payload.clientId,
    code: payload.code,
    redirect_uri: payload.redirectUri,
    oauth_action: payload.oauthAction,
  })
}

export const updateSpearlyOauthTokenNonceAction = async (nonce: string) => {
  try {
    await api<any>("put", "oauth/token", {
      token: {
        nonce: nonce,
      },
    })
  } catch (error: any) {
    console.error(error)
    removeAuthData()
  }
}

type PostOauthSpearlyCallbackPayload = {
  clientId: string
  code: string
  redirectUri: string
  nonce?: string
}

export const postOauthSpearlyCallback = async (
  payload: PostOauthSpearlyCallbackPayload,
) => {
  return await api<any>(
    "post",
    "oauth/spearly/callback",
    {
      client_id: payload.clientId,
      code: payload.code,
      grant_type: "authorization_code",
      redirect_uri: payload.redirectUri,
      nonce: payload.nonce,
    },
    {
      login: true,
    },
  )
}

export const getRedirectUri = () => {
  return process.env.REACT_APP_SPEARLY_REDIRECT_URI || ""
}

export const getClientId = () => {
  return process.env.REACT_APP_SPEARLY_CLIENT_ID || ""
}

export const removeAuthData = () => {
  removeCurrentTeam()
  removeSpearlyToken()
}
