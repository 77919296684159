import { DownOutlined } from "@ant-design/icons"
import { Dropdown } from "antd"
import React from "react"
import { useHistory, useLocation } from "react-router-dom"
import styled from "styled-components"

import { Locale } from "../../interfaces"
import { getLang, setLang, t } from "../../lib/i18n"

const langList: Locale[] = ["ja", "en_us", "pt_br"]

const LanguageDropdown = (): JSX.Element => {
  const location = useLocation()
  const history = useHistory()

  const onChangeLanguage = (lang: Locale) => {
    setLang(lang)
    history.replace({
      pathname: location.pathname,
      hash: location.hash,
      search: location.search,
    })
  }

  const LanguageItems = langList.map((lang) => {
    return (
      <StyledItem key={lang}>
        <LangButton type="button" onClick={() => onChangeLanguage(lang)}>
          {t(lang)}
        </LangButton>
      </StyledItem>
    )
  })

  const OverlayMenu = () => {
    return <StyledMenu>{LanguageItems}</StyledMenu>
  }

  return (
    <Dropdown overlay={OverlayMenu} trigger={["click"]}>
      <StyledButton type="button">
        {t(getLang())}
        <DownOutlined />
      </StyledButton>
    </Dropdown>
  )
}

const StyledButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border: 1px solid #e4e6e8;
  border-radius: 15px;
  color: #8083a3;
  width: 146px;
  height: 43px;
  padding: 13px;
`

const StyledMenu = styled.ul`
  display: block;
  border-radius: 15px;
  background: #fff;
  overflow: hidden;
  border: 1px solid #e4e6e8;
`

const StyledItem = styled.li`
  &:not(:first-child) {
    border-top: 1px solid #e4e6e8;
  }
`

const LangButton = styled.button`
  background: #fff;
  border: none;
  color: #8083a3;
  width: 146px;
  height: 43px;
  text-align: left;
  padding-left: 13px;

  &:hover {
    background: #6f6eff;
    color: #fff;
    opacity: 0.8;
  }
`

export default LanguageDropdown
